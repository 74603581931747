import React from 'react';

const SubscriberDeleteModal = ({ isOpen, onClose, subscriber, onDelete, isDeleting }) => {
    if (!isOpen || !subscriber) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg p-6 w-full max-w-md mx-3 sm:mx-0 shadow-xl">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-xl font-bold text-gray-800">Confirm Delete</h2>
                    <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
                
                <div className="mb-6">
                    <div className="bg-red-100 p-4 rounded-lg mb-4">
                        <div className="flex justify-center">
                            <svg className="w-12 h-12 text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                            </svg>
                        </div>
                    </div>
                    
                    <p className="text-gray-700 mb-2">Are you sure you want to delete this subscriber?</p>
                    <div className="font-medium text-center py-2 px-4 bg-gray-50 rounded-md mb-4">
                        {subscriber?.company_name || 'Unknown Company'}
                    </div>
                    <p className="text-sm text-gray-500">This action cannot be undone.</p>
                </div>
                
                <div className="flex space-x-3">
                    <button
                        onClick={() => onDelete(subscriber)}
                        disabled={isDeleting}
                        className={`flex-1 py-2 px-4 bg-red-600 text-white rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 ${isDeleting ? 'opacity-50 cursor-not-allowed' : ''}`}
                    >
                        {isDeleting ? 'Deleting...' : 'Delete Subscriber'}
                    </button>
                    <button
                        onClick={onClose}
                        disabled={isDeleting}
                        className="flex-1 py-2 px-4 border border-gray-300 text-gray-700 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SubscriberDeleteModal; 