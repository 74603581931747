import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { ImFileEmpty, ImSearch, ImList, ImTable, ImSpinner2 } from 'react-icons/im';
import { DNA } from 'react-loader-spinner';
import { useSelector } from 'react-redux';
import { api_url } from '../config';
import { toast } from 'react-toastify';
import api from '../utils/Api';
import debounce from 'lodash/debounce';

// Import custom components
import SubscriberVerificationModal from '../components/SubscriberVerificationModal';
import SubscriberDetailsModal from '../components/SubscriberDetailsModal';
import SubscriberDeleteModal from '../components/SubscriberDeleteModal';
import SubscriberCard from '../components/SubscriberCard';
import SubscriberListItem from '../components/SubscriberListItem';
import SubscriberPagination from '../components/SubscriberPagination';

const SubscribersPage = () => {
    const { id } = useSelector((state) => state.auth);
    
    // State Management
    const [loading, setLoading] = useState(true);
    const [subscribers, setSubscribers] = useState([]);
    const [allSubscribers, setAllSubscribers] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [viewMode, setViewMode] = useState('grid');
    const [pagination, setPagination] = useState({
        currentPage: 1,
        lastPage: 1,
        total: 0,
        perPage: 15
    });
    const [isReloading, setIsReloading] = useState(false);

    // Modal States
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [subscriberToVerify, setSubscriberToVerify] = useState('');
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [subscriberToDelete, setSubscriberToDelete] = useState(null);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
    const [subscriberDetails, setSubscriberDetails] = useState(null);

    // API Calls
    const fetchSubscribers = async () => {
        try {
            setLoading(true);
            
            const possibleEndpoints = [
                `${api_url}/all-subscribers`,
                `${api_url}/user/${id}/subscriber`,
                `${api_url}/subscribers`,
                `${api_url}/subscriber`
            ];
            
            let response = null;
            let error = null;
            
            const params = {
                    page: pagination.currentPage,
                per_page: pagination.perPage
            };
            
            for (const endpoint of possibleEndpoints) {
                try {
                    response = await api.get(endpoint, { params });
                    if (response) break;
                } catch (err) {
                    error = err;
                }
            }
            
            if (!response) {
                throw error || new Error('API endpoints failed');
            }
            
            if (response.data && response.data.data) {
                const fetchedSubscribers = response.data.data;
                setAllSubscribers(fetchedSubscribers);
                
                if (searchQuery.trim() !== '') {
                    filterSubscribers(fetchedSubscribers, searchQuery);
                } else {
                    setSubscribers(fetchedSubscribers);
                }
                
                if (response.data.meta) {
            setPagination(prev => ({
                ...prev,
                        currentPage: response.data.meta.current_page || 1,
                        lastPage: response.data.meta.last_page || 1,
                        total: response.data.meta.total || 0,
                        perPage: response.data.meta.per_page || 15
                    }));
                }
            } else {
                const fetchedSubscribers = Array.isArray(response.data) ? response.data : [];
                setAllSubscribers(fetchedSubscribers);
                
                if (searchQuery.trim() !== '') {
                    filterSubscribers(fetchedSubscribers, searchQuery);
                } else {
                    setSubscribers(fetchedSubscribers);
                }
            }
        } catch (error) {
            console.error('Error fetching subscribers:', error);
            toast.error('Failed to fetch subscribers');
            setSubscribers([]);
            setAllSubscribers([]);
        } finally {
            setLoading(false);
        }
    };

    // Client-side filter function with a useCallback wrapper
    const filterSubscribersMemoized = useCallback((subscribersToFilter, query) => {
        if (!query || query.trim() === '') {
            setSubscribers(subscribersToFilter);
            return;
        }
        
        const lowercaseQuery = query.toLowerCase().trim();
        
        const filtered = subscribersToFilter.filter(subscriber => (
            (subscriber.company_name && subscriber.company_name.toLowerCase().includes(lowercaseQuery)) ||
            (subscriber.email && subscriber.email.toLowerCase().includes(lowercaseQuery)) ||
            (subscriber.admin_name && subscriber.admin_name.toLowerCase().includes(lowercaseQuery)) ||
            (subscriber.admin && subscriber.admin.toLowerCase().includes(lowercaseQuery)) ||
            (subscriber.company_address && subscriber.company_address.toLowerCase().includes(lowercaseQuery)) ||
            (subscriber.company_phone && subscriber.company_phone.toLowerCase().includes(lowercaseQuery))
        ));
        
        setSubscribers(filtered);
    }, []);

    // Standard function for direct filtering calls
    const filterSubscribers = (subscribersToFilter, query) => {
        filterSubscribersMemoized(subscribersToFilter, query);
    };

    // Create a debounced search function with useMemo
    const debouncedSearchHandler = useMemo(
        () => debounce((query) => {
            filterSubscribersMemoized(allSubscribers, query);
        }, 300),
        [allSubscribers, filterSubscribersMemoized]
    );

    // Handle search input change
    const handleSearchChange = (e) => {
        const query = e.target.value;
        setSearchQuery(query);
        debouncedSearchHandler(query);
    };

    // Clear search and reset results
    const handleClearSearch = () => {
        setSearchQuery('');
        setSubscribers(allSubscribers);
    };

    // Pagination Handlers
    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= pagination.lastPage) {
            setPagination(prev => ({
                ...prev,
                currentPage: newPage
            }));
        }
    };

    // Modal Handlers
    const handleOpenVerifyModal = (subscriber) => {
        setSubscriberToVerify(subscriber);
        setIsModalOpen(true);
    };

    const handleCloseVerifyModal = () => {
        setIsModalOpen(false);
        setSubscriberToVerify('');
    };

    const handleOpenDetailsModal = (subscriber) => {
        setSubscriberDetails(subscriber);
        setIsDetailsModalOpen(true);
    };

    const handleCloseDetailsModal = () => {
        setIsDetailsModalOpen(false);
        setSubscriberDetails(null);
    };

    // Delete Handlers
    const handleOpenDeleteModal = (subscriber) => {
        setSubscriberToDelete(subscriber);
        setIsDeleteModalOpen(true);
    };

    const handleCloseDeleteModal = () => {
        setIsDeleteModalOpen(false);
        setSubscriberToDelete(null);
    };

    const handleDeleteSubscriber = async () => {
        if (!subscriberToDelete) return;
        
        setIsDeleting(true);
        try {
            await api.delete(`${api_url}/subscribers/${subscriberToDelete.id}`);
            toast.success("Subscriber deleted successfully");
            setSubscribers(prev => prev.filter(s => s.id !== subscriberToDelete.id));
            setAllSubscribers(prev => prev.filter(s => s.id !== subscriberToDelete.id));
            handleCloseDeleteModal();
        } catch (error) {
            console.error("Error deleting subscriber:", error);
            toast.error("Failed to delete subscriber");
        } finally {
            setIsDeleting(false);
        }
    };

    const handleSubscriberUpdate = (updatedSubscriber) => {
        // Update the subscriber in the list
        setSubscribers(prevSubscribers => 
            prevSubscribers.map(sub => 
                sub.id === updatedSubscriber.id ? updatedSubscriber : sub
            )
        );
        // Update the subscriber details if it's the one being viewed
        if (subscriberDetails?.id === updatedSubscriber.id) {
            setSubscriberDetails(updatedSubscriber);
        }
    };

    const handleReload = async () => {
        setIsReloading(true);
        try {
            await fetchSubscribers();
            toast.success('Data refreshed successfully');
        } catch (error) {
            console.error('Error reloading data:', error);
            toast.error('Failed to reload data');
        } finally {
            setIsReloading(false);
        }
    };

    // Effects for pagination
    useEffect(() => {
        fetchSubscribers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination.currentPage]);

    useEffect(() => {
        fetchSubscribers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Main Render
    return (
        <div className="container mx-auto p-4 pb-10">
            <div className="mb-6">
                <h1 className="text-2xl font-bold text-gray-800">Subscribers</h1>
                <p className="text-sm text-gray-500">Manage and view all subscribers</p>
            </div>

            <div className="flex flex-col md:flex-row md:justify-between md:items-center gap-4 mb-6">
                {/* Search Box */}
                <div className="relative max-w-md w-full">
                    <input
                        type="text"
                        placeholder="Search subscribers..."
                        value={searchQuery}
                        onChange={handleSearchChange}
                        className="w-full px-4 py-2.5 pl-10 text-sm border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    />
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <ImSearch className="w-4 h-4 text-gray-400" />
                    </div>
                    {searchQuery && (
                        <button
                            onClick={handleClearSearch}
                            className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-400 hover:text-gray-600"
                        >
                            <span className="text-xl">&times;</span>
                        </button>
                    )}
                </div>

                {/* View Toggle and Reload */}
                <div className="flex items-center gap-2">
                    <div className="flex items-center bg-white border border-gray-200 rounded-lg shadow-sm p-1">
                        <button
                            onClick={() => setViewMode('grid')}
                            className={`flex items-center px-3 py-1.5 rounded-md transition-colors ${
                                viewMode === 'grid' 
                                    ? 'bg-blue-50 text-blue-600' 
                                    : 'text-gray-500 hover:text-gray-700 hover:bg-gray-50'
                            }`}
                        >
                            <ImTable className="w-4 h-4 mr-1.5" />
                            <span className="text-xs font-medium">Grid</span>
                        </button>
                        <button
                            onClick={() => setViewMode('list')}
                            className={`flex items-center px-3 py-1.5 rounded-md transition-colors ${
                                viewMode === 'list' 
                                    ? 'bg-blue-50 text-blue-600' 
                                    : 'text-gray-500 hover:text-gray-700 hover:bg-gray-50'
                            }`}
                        >
                            <ImList className="w-4 h-4 mr-1.5" />
                            <span className="text-xs font-medium">List</span>
                        </button>
                    </div>
                    <button
                        onClick={handleReload}
                        disabled={isReloading}
                        className="flex items-center px-3 py-1.5 bg-white border border-gray-200 rounded-lg shadow-sm hover:bg-gray-50 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                        title="Reload data"
                    >
                        <ImSpinner2 className={`w-4 h-4 mr-1.5 ${isReloading ? 'animate-spin' : ''}`} />
                        <span className="text-xs font-medium text-gray-700">Reload</span>
                    </button>
                </div>
            </div>

            {/* Stats Summary */}
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
                <div className="bg-white rounded-lg border border-gray-200 shadow-sm p-4">
                    <div className="flex items-center">
                        <div className="flex-shrink-0 bg-blue-100 rounded-md p-2">
                            <svg className="w-5 h-5 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                            </svg>
                        </div>
                        <div className="ml-4">
                            <h3 className="text-sm font-medium text-gray-500">Total Subscribers</h3>
                            <p className="text-lg font-semibold text-gray-800">{pagination.total || 0}</p>
                        </div>
                    </div>
                </div>
                
                <div className="bg-white rounded-lg border border-gray-200 shadow-sm p-4">
                    <div className="flex items-center">
                        <div className="flex-shrink-0 bg-green-100 rounded-md p-2">
                            <svg className="w-5 h-5 text-green-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                        </div>
                        <div className="ml-4">
                            <h3 className="text-sm font-medium text-gray-500">Verified</h3>
                            <p className="text-lg font-semibold text-gray-800">
                                {subscribers.filter(s => s.is_verified).length || 0}
                            </p>
                        </div>
                    </div>
        </div>
                
                <div className="bg-white rounded-lg border border-gray-200 shadow-sm p-4 sm:col-span-2">
                    <h3 className="text-sm font-medium text-gray-500 mb-2">Search Results</h3>
                    <p className="text-xs text-gray-600">
                        {searchQuery ? 
                            `Showing ${subscribers.length} result(s) for "${searchQuery}"` : 
                            "Use the search box to filter subscribers by name, email, address, or phone number."}
                    </p>
                </div>
            </div>

            {/* Loading State */}
            {loading && (
                <div className="flex justify-center items-center h-64">
                    <DNA
                        visible={true}
                        height={80}
                        width={80}
                        ariaLabel="dna-loading"
                        wrapperStyle={{}}
                        wrapperClass="dna-wrapper"
                    />
                </div>
            )}

            {/* Empty State */}
            {!loading && subscribers.length === 0 && (
                <div className="flex flex-col items-center justify-center py-12 bg-white rounded-lg border border-gray-200 shadow-sm">
                    <div className="bg-gray-100 p-4 rounded-full mb-4">
                        <ImFileEmpty className="text-4xl text-gray-400" />
                    </div>
                    <h3 className="text-lg font-medium text-gray-800 mb-1">No subscribers found</h3>
                    <p className="text-sm text-gray-500 max-w-md text-center">
                                    {searchQuery
                            ? `No results match "${searchQuery}". Try adjusting your search criteria or clear the search.`
                            : "There are no subscribers available yet. They will appear here once added."}
                                </p>
                                {searchQuery && (
                                    <button
                                        onClick={handleClearSearch}
                            className="mt-4 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                    >
                                        Clear search
                                    </button>
                                )}
                        </div>
                    )}

            {/* Subscribers Grid/List */}
            {!loading && subscribers.length > 0 && (
                <div className={viewMode === 'grid' 
                    ? "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5" 
                    : "bg-white rounded-lg border border-gray-200 shadow-sm overflow-hidden"}
                >
                    {subscribers.map(subscriber => 
                        viewMode === 'grid' 
                            ? (
                                <SubscriberCard 
                                    key={subscriber.id} 
                                    subscriber={subscriber} 
                                    onVerify={handleOpenVerifyModal}
                                    onViewDetails={handleOpenDetailsModal}
                                    onDelete={handleOpenDeleteModal}
                                />
                            ) 
                            : (
                                <SubscriberListItem 
                                    key={subscriber.id} 
                                    subscriber={subscriber} 
                                    onVerify={handleOpenVerifyModal}
                                    onViewDetails={handleOpenDetailsModal}
                                    onDelete={handleOpenDeleteModal}
                                />
                            )
                    )}
                </div>
            )}

            {/* Pagination */}
            {!loading && subscribers.length > 0 && (
                <SubscriberPagination 
                    pagination={pagination}
                    onPageChange={handlePageChange}
                />
            )}

            {/* Modals */}
            {isModalOpen && (
                <SubscriberVerificationModal
                    isOpen={isModalOpen}
                    onClose={handleCloseVerifyModal}
                    subscriber={subscriberToVerify}
                    userId={id}
                />
            )}

            {isDetailsModalOpen && (
                <SubscriberDetailsModal 
                    isOpen={isDetailsModalOpen}
                    onClose={handleCloseDetailsModal}
                    subscriber={subscriberDetails}
                    onVerify={handleOpenVerifyModal}
                    onUpdateSuccess={handleSubscriberUpdate}
                />
            )}

            {isDeleteModalOpen && (
                <SubscriberDeleteModal 
                    isOpen={isDeleteModalOpen}
                    onClose={handleCloseDeleteModal}
                    subscriber={subscriberToDelete}
                    onDelete={handleDeleteSubscriber}
                    isDeleting={isDeleting}
                />
            )}
        </div>
    );
};

export default SubscribersPage;