import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import api from '../utils/Api';
import { api_url } from '../config';

const UpdateSubscriberProfile = ({ isOpen, onClose, subscriber, onUpdateSuccess }) => {
    const [formData, setFormData] = useState({
        email: '',
        company_name: '',
        company_address: '',
        admin_name: '',
        company_phone: '',
        password: ''
    });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (subscriber) {
            setFormData({
                email: subscriber.email || '',
                company_name: subscriber.company_name || '',
                company_address: subscriber.company_address || '',
                admin_name: subscriber.admin_name || subscriber.admin || '',
                company_phone: subscriber.company_phone || '',
                password: ''
            });
        }
    }, [subscriber]);

    if (!isOpen) return null;

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleUpdateProfile = async () => {
        try {
            setLoading(true);
            // Remove empty fields to optimize payload
            const updateData = Object.fromEntries(
                Object.entries(formData).filter(([_, value]) => value !== '')
            );

            const response = await api.put(`${api_url}/subscribers/${subscriber.id}`, updateData);
            
            // Show success message
            toast.success('Profile updated successfully');
            
            // Call the onUpdateSuccess callback to trigger parent component reload
            if (onUpdateSuccess) {
                onUpdateSuccess(response.data.data);
            }
            
            // Close the modal
            onClose();
        } catch (error) {
            console.error('Update error:', error);
            toast.error(error.response?.data?.message || 'Failed to update profile');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg w-full max-w-2xl mx-3 sm:mx-0">
                <div className="flex justify-between items-center p-4 border-b">
                    <h2 className="text-xl font-semibold text-gray-800">Update Account Profile</h2>
                    <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
                <form className="grid grid-cols-1 sm:grid-cols-2 gap-4 p-6">
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-2">Email</label>
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            className="border border-gray-300 rounded-md p-2 w-full focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-2">Company Name</label>
                        <input
                            type="text"
                            name="company_name"
                            value={formData.company_name}
                            onChange={handleChange}
                            className="border border-gray-300 rounded-md p-2 w-full focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        />
                    </div>
                    <div className="sm:col-span-2">
                        <label className="block text-sm font-medium text-gray-700 mb-2">Company Address</label>
                        <input
                            type="text"
                            name="company_address"
                            value={formData.company_address}
                            onChange={handleChange}
                            className="border border-gray-300 rounded-md p-2 w-full focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-2">Admin Full Name</label>
                        <input
                            type="text"
                            name="admin_name"
                            value={formData.admin_name}
                            onChange={handleChange}
                            className="border border-gray-300 rounded-md p-2 w-full focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-2">Business Phone Number</label>
                        <input
                            type="text"
                            name="company_phone"
                            value={formData.company_phone}
                            onChange={handleChange}
                            className="border border-gray-300 rounded-md p-2 w-full focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-2">New Password (Optional)</label>
                        <input
                            type="password"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            className="border border-gray-300 rounded-md p-2 w-full focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                            placeholder="Leave blank to keep current password"
                        />
                    </div>
                </form>
                <div className="flex justify-end gap-3 p-4 border-t">
                    <button
                        onClick={onClose}
                        className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={handleUpdateProfile}
                        disabled={loading}
                        className="px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                        {loading ? 'Updating...' : 'Update Profile'}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default UpdateSubscriberProfile;
