import React from 'react';
import { useSelector } from 'react-redux';
import UpdatePasswordForm from '../components/UpdatePasswordForm';
import { FaRegUser, FaRegEnvelope } from 'react-icons/fa';
import { BsCalendarDate } from 'react-icons/bs';
import { MdOutlineBadge } from 'react-icons/md';

const ProfilePage = () => {
    const { first_name, last_name, email, type, created_at } = useSelector((state) => state.auth);
    
    const formattedDate = created_at ? new Date(created_at).toLocaleDateString() : '';
    
    return (
        <div className='w-full h-full max-w-5xl mx-auto px-4 py-8'>
            <div className='bg-gradient-to-r from-blue-50 to-indigo-50 rounded-xl shadow-lg p-8 mb-8 border border-blue-100'>
                <div className='flex flex-col md:flex-row items-center md:items-start gap-8'>
                    {/* Avatar Section */}
                    <div className='flex flex-col items-center'>
                        <div className='relative'>
                            <img 
                                src={`https://ui-avatars.com/api/?name=${first_name}+${last_name}&background=random&size=200&bold=true`} 
                                alt="Profile Avatar" 
                                className="h-40 w-40 rounded-full shadow-md border-4 border-white" 
                            />
                            <div className='absolute -bottom-2 right-0 bg-blue-600 text-white px-3 py-1 rounded-full text-xs font-semibold shadow-md'>
                                {type || 'User'}
                            </div>
                        </div>
                        <h1 className='mt-4 text-2xl font-bold text-gray-800'>{first_name} {last_name}</h1>
                    </div>
                    
                    {/* User Details Section */}
                    <div className='flex-1 bg-white rounded-xl p-6 shadow-sm'>
                        <h2 className='text-xl font-semibold mb-6 text-gray-800 border-b pb-2'>Profile Information</h2>
                        
                        <div className='grid grid-cols-1 md:grid-cols-2 gap-6'>
                            <div className='flex items-start gap-3'>
                                <div className='p-3 bg-blue-100 rounded-lg'>
                                    <FaRegUser className='text-blue-600' size={20} />
                                </div>
                                <div>
                                    <p className='text-sm text-gray-500 font-medium'>Full Name</p>
                                    <p className='font-semibold text-gray-700'>{first_name} {last_name}</p>
                                </div>
                            </div>
                            
                            <div className='flex items-start gap-3'>
                                <div className='p-3 bg-blue-100 rounded-lg'>
                                    <FaRegEnvelope className='text-blue-600' size={20} />
                                </div>
                                <div>
                                    <p className='text-sm text-gray-500 font-medium'>Email Address</p>
                                    <p className='font-semibold text-gray-700'>{email}</p>
                                </div>
                            </div>
                            
                            <div className='flex items-start gap-3'>
                                <div className='p-3 bg-blue-100 rounded-lg'>
                                    <MdOutlineBadge className='text-blue-600' size={20} />
                                </div>
                                <div>
                                    <p className='text-sm text-gray-500 font-medium'>Account Type</p>
                                    <p className='font-semibold text-gray-700'>{type || 'Standard User'}</p>
                                </div>
                            </div>
                            
                            <div className='flex items-start gap-3'>
                                <div className='p-3 bg-blue-100 rounded-lg'>
                                    <BsCalendarDate className='text-blue-600' size={20} />
                                </div>
                                <div>
                                    <p className='text-sm text-gray-500 font-medium'>Member Since</p>
                                    <p className='font-semibold text-gray-700'>{formattedDate}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            {/* Password Update Section */}
            <div className='bg-white rounded-xl shadow-lg p-8 border border-gray-100'>
                <h2 className='text-xl font-semibold mb-6 text-gray-800 border-b pb-2'>Security</h2>
                <UpdatePasswordForm />
            </div>
        </div>
    );
}

export default ProfilePage; 