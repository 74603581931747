import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        id: sessionStorage.getItem('id') || null,
        uuid: sessionStorage.getItem('uuid') || null,
        first_name: sessionStorage.getItem('first_name') || null,
        last_name: sessionStorage.getItem('last_name') || null,
        email: sessionStorage.getItem('email') || null,
        email_verified_at:sessionStorage.getItem('email_verified_at') ,
        created_at:sessionStorage.getItem('created_at') || null,
        updated_at:sessionStorage.getItem('updated_at') || null,
        reset_password:sessionStorage.getItem('reset_password') || null,
        type:sessionStorage.getItem('type') || null,
        roles:sessionStorage.getItem('roles') || [],
        token: sessionStorage.getItem('token') || null,
        token_type: 'Bearer',
        loading: false
    },
    reducers: {
        setAuthData: (state, action) => {
            const { id, uuid, first_name,last_name,roles, email, email_verified_at, created_at, updated_at, token, reset_password, type } = action.payload;
            console.log('Setting auth data - roles received:', roles);
            
            // Set default roles if empty
            const userRoles = (!roles || (Array.isArray(roles) && roles.length === 0)) 
                ? ['subscriberViewer', 'subscriberWriter', 'subscriberAdmin']
                : roles;
                
            state.id = id;
            state.uuid = uuid;
            state.first_name = first_name;
            state.last_name = last_name;
            state.email = email;
            state.roles = userRoles;
            state.email_verified_at = email_verified_at;
            state.created_at = created_at;
            state.updated_at = updated_at;
            state.reset_password = reset_password;
            state.type = type;
            state.token = token;

            // Update sessionStorage...here adey use for the login
            sessionStorage.setItem('token', token);
            sessionStorage.setItem('reset_password', reset_password);
            sessionStorage.setItem('type', type);
            sessionStorage.setItem('id', id);
            sessionStorage.setItem('uuid', uuid);
            sessionStorage.setItem('last_name', last_name);
            sessionStorage.setItem('first_name', first_name);
            sessionStorage.setItem('email', email);
            sessionStorage.setItem('roles', Array.isArray(userRoles) ? userRoles.join(',') : userRoles || '');
            sessionStorage.setItem('created_at', created_at);
            sessionStorage.setItem('updated_at', updated_at);
            sessionStorage.setItem('email_verified_at', email_verified_at);
        },
        clearAuthData: (state) => {
            state.id = null;
            state.uuid = null;
            state.first_name = null;
            state.last_name = null;
            state.email = null;
            state.email_verified_at = null;
            state.created_at = null;
            state.updated_at = null;
            state.token = null;
            state.reset_password = null;
            state.roles = null;
            state.type = null;
            state.loading= false

            // Clear sessionStorage...usually I dey use for the logout
            sessionStorage.removeItem('token');
            sessionStorage.removeItem('reset_password');
            sessionStorage.removeItem('type');
            sessionStorage.removeItem('id');
            sessionStorage.removeItem('uuid');
            sessionStorage.removeItem('first_name');
            sessionStorage.removeItem('last_name');
            sessionStorage.removeItem('email');
            sessionStorage.removeItem('created_at');
            sessionStorage.removeItem('updated_at');
            sessionStorage.removeItem('roles');
            sessionStorage.removeItem('email_verified_at');
        },
     
    },
});

export const selectAuthData = (state) => state.auth;
export const selectRoles = (state) =>state.auth.roles;
export const { setAuthData, clearAuthData} = authSlice.actions;
export default authSlice.reducer;