import axios from 'axios';
import store from '../Store'; 
import { clearAuthData } from '../Redux/AuthSlice';
import { toast } from 'react-toastify';

const api = axios.create({
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
    }
});

// Add request interceptor to add auth token to all requests
api.interceptors.request.use(
    (config) => {
        const state = store.getState();
        const token = state.auth.token;
        
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        
        // Log request details
        console.log('API Request:', { 
            url: config.url,
            method: config.method,
            params: config.params,
            headers: {
                Authorization: config.headers['Authorization'] ? `Bearer ${token.substring(0, 10)}...` : 'None',
                'Content-Type': config.headers['Content-Type']
            }
        });
        return config;
    },
    (error) => {
        console.error('API Request Error:', error);
        return Promise.reject(error);
    }
);

// Response interceptor for handling auth errors
api.interceptors.response.use(
    (response) => {
        console.log('API Response:', {
            url: response.config.url,
            status: response.status,
            statusText: response.statusText,
            dataSize: JSON.stringify(response.data).length,
            dataPreview: response.data ? (response.data.data ? `${response.data.data.length} items` : 'No data array found') : 'No data'
        });
        return response;
    },
    (error) => {
        console.error('API Response Error:', {
            url: error.config?.url,
            status: error.response?.status,
            statusText: error.response?.statusText,
            message: error.message,
            responseData: error.response?.data
        });
        
        // Handle specific error cases
        if (error.response) {
            switch (error.response.status) {
                case 401:
                    store.dispatch(clearAuthData());
                    toast.error('Session expired. Please login again');
                    break;
                case 403:
                    toast.error('You do not have permission to perform this action');
                    break;
                case 419:
                    store.dispatch(clearAuthData());
                    toast.error('Session expired. Please login again');
                    break;
                case 0: // CORS error often results in status 0
                    toast.error('Unable to connect to the server. Please check your connection');
                    break;
                default:
                    if (error.response.data?.message === "subscription-expired") {
                        store.dispatch(clearAuthData());
                        toast.error('Your subscription has expired. Please renew to continue');
                    } else {
                        toast.error(error.response.data?.message || 'An error occurred');
                    }
            }
        } else if (error.request) {
            // Network error
            toast.error('Network error. Please check your connection');
        }
        
        return Promise.reject(error);
    }
);

export default api;