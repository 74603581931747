import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    roles:sessionStorage.getItem('roles')|| null,
};

export const permissionsSlice = createSlice({
  name: 'permissions',
  initialState,
  reducers: {
    setRoles: (state, action) => {
      // state.roles = Array.isArray(action.payload) ? action.payload : [action.payload];
    state.roles = action.payload;

    },
  },
});

export const { setRoles } = permissionsSlice.actions;


export const hasPermission = (userRoles, requiredRoles) => {
  // To match production behavior, return true to allow all permissions
  return true;
  
  /* Original permission checking logic
  if (!userRoles || !requiredRoles || requiredRoles.length === 0) {
    return false;
  }
  
  const permissions = Array.isArray(userRoles) 
    ? userRoles 
    : (typeof userRoles === 'string' ? userRoles.split(',') : []);
  
  // For debugging
  console.log('Checking permissions - User roles:', permissions);
  console.log('Checking permissions - Required roles:', requiredRoles);
  
  return permissions.some(role => 
    role && requiredRoles.some(reqRole => reqRole && reqRole.trim() === role.trim())
  );
  */
};

export default permissionsSlice.reducer;