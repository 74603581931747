import React from 'react';

const SubscriberCard = ({ subscriber, onVerify, onViewDetails, onDelete }) => {
    
    return (
        <div className="border border-gray-200 rounded-xl shadow-sm hover:shadow-md transition-all duration-300 p-5 bg-white overflow-hidden group">
            <div className="flex gap-4 items-start">
                <div className="flex-shrink-0 transform transition-transform group-hover:scale-110 duration-300">
                    <img
                        src={`https://ui-avatars.com/api/?name=${subscriber.company_name || 'Unknown'}&background=random&bold=true&size=60`}
                        alt={`${subscriber.company_name || 'Company'} Logo`}
                        className="h-14 w-14 rounded-lg shadow-sm"
                    />
                </div>
                <div className="flex-1">
                    <h3 className="text-lg font-semibold text-gray-800 flex items-center">
                        {subscriber?.company_name || 'Unknown Company'}                        
                        {subscriber?.is_verified && (
                            <span className="ml-2 bg-blue-100 text-blue-800 text-xs px-2 py-0.5 rounded-full">Verified</span>
                        )}
                    </h3>
                    <span className="ml-2 text-sm text-gray-500">
                            ({subscriber?.admin_name || 'No admin'})
                        </span>
                    
                    <div className="mt-2 grid grid-cols-1 gap-y-2">
                        <a 
                            href={`mailto:${subscriber?.email || ''}`} 
                            className="flex items-center text-sm text-blue-600 hover:text-blue-800 hover:underline transition-colors duration-200"
                            title={subscriber?.email || ''}
                        >
                            <svg className="w-4 h-4 mr-2 text-gray-500 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                            </svg>
                            <span className="truncate max-w-[180px] sm:max-w-[220px]">{subscriber?.email || 'No email provided'}</span>
                        </a>
                        
                        {subscriber?.company_phone && (
                            <a 
                                href={`tel:${subscriber?.company_phone}`}
                                className="flex items-center text-sm text-blue-600 hover:text-blue-800 hover:underline transition-colors duration-200"
                                title={subscriber?.company_phone}
                            >
                                <svg className="w-4 h-4 mr-2 text-gray-500 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                                </svg>
                                <span className="truncate max-w-[180px] sm:max-w-[220px]">{subscriber?.company_phone}</span>
                            </a>
                        )}
                        
                        <div className="flex items-center text-sm text-gray-600" title={subscriber?.company_address || "Address not provided"}>
                            <svg className="w-4 h-4 mr-2 text-gray-500 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                            </svg>
                            <span className="truncate max-w-[180px] sm:max-w-[220px]">{subscriber?.company_address || "Address not provided"}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex justify-end space-x-2 mt-4 pt-3 border-t border-gray-100">
                <button
                    onClick={() => onVerify(subscriber)}
                    className="px-3 py-1.5 text-xs font-medium bg-blue-50 text-blue-600 rounded-md hover:bg-blue-100 transition-colors focus:ring-2 focus:ring-blue-400 focus:outline-none flex items-center"
                >
                    <svg className="w-3.5 h-3.5 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
                    </svg>
                    Verify
                </button>
                <button
                    onClick={() => onViewDetails(subscriber)}
                    className="px-3 py-1.5 text-xs font-medium bg-gray-50 text-gray-700 rounded-md hover:bg-gray-100 transition-colors focus:ring-2 focus:ring-gray-400 focus:outline-none flex items-center"
                >
                    <svg className="w-3.5 h-3.5 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                    </svg>
                    View Details
                </button>
                <button
                    onClick={() => onDelete(subscriber)}
                    className="px-3 py-1.5 text-xs font-medium bg-red-50 text-red-600 rounded-md hover:bg-red-100 transition-colors focus:ring-2 focus:ring-red-400 focus:outline-none flex items-center"
                >
                    <svg className="w-3.5 h-3.5 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                    </svg>
                    Delete
                </button>
            </div>
        </div>
    );
};

export default SubscriberCard; 