import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router';
import { clearAuthData, selectRoles } from '../Redux/AuthSlice';
import { hasPermission } from '../Redux/PermissionSlice';

const PrivateRoute = ({ type }) => {
    const location = useLocation();
    const token = useSelector((state) => state.auth.token);
    const productType = useSelector((state) => state.auth.type);
    const dispatch = useDispatch();

    const selectedRoles = useSelector(selectRoles);
    const userRoles = Array.isArray(selectedRoles) 
        ? selectedRoles 
        : (selectedRoles ? selectedRoles.split(',').map(role => role.trim()) : []);

    const whitelistedRoutes = [
        '/dashboard/overview',
        '/dashboard/profile',
        '/dashboard/subscribers',
        '/dashboard/backlogs',
        '/dashboard/tasks',
        '/dashboard/qa',
        '/dashboard/archive',
        '/dashboard/departments',
        '/dashboard/users'
    ];
    
    // To match production, always allow access to all routes
    const hasRequiredPermissions = true;
    
    /*
    const hasRequiredPermissions = 
      whitelistedRoutes.includes(location.pathname) ||
      hasPermission(userRoles, location.state?.requiredRoles || []);
    */

    console.log('User roles:', userRoles);
    console.log('Required roles:', location.state?.requiredRoles || []);
    console.log('Has permissions:', hasRequiredPermissions);
    // Define isAuthenticated and isCorrectProductType here
    const isAuthenticated = token && token !== "";
    const isCorrectProductType = productType === type;

    useEffect(() => {
        // If not authenticated or incorrect product type, clear auth data
        if (!isAuthenticated || !isCorrectProductType) {
            dispatch(clearAuthData());
        }
    }, [dispatch, token, productType, type,isAuthenticated,isCorrectProductType]);

    // Render Outlet if authenticated and correct product type, else navigate to login
    return (isAuthenticated && isCorrectProductType && hasRequiredPermissions) ? <Outlet /> : <Navigate to='/' />;
}

export default PrivateRoute;
