import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { api_url } from '../config';
import api from '../utils/Api';
import { toast } from 'react-toastify';

const SubscriberVerificationModal = ({ isOpen, onClose, company, subscriber, userId }) => {
    // Support both 'company' and 'subscriber' props for backward compatibility
    const subscriberData = subscriber || company || {};
    const [step, setStep] = useState(0);
    const [email, setEmail] = useState(subscriberData.email || '');
    const [verificationCode, setVerificationCode] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const {id, token} = useSelector((state) => state.auth);
    
    // Use userId from props if provided, otherwise use id from Redux
    const userIdToUse = userId || id;

    useEffect(() => {
        // Update email if subscriber/company changes
        if (subscriberData && subscriberData.email) {
            setEmail(subscriberData.email);
        }
        setStep(0);
        setVerificationCode('');
        setError('');
    }, [isOpen, subscriberData]);

    if (!isOpen) return null;
    
    // Guard against undefined subscriber
    if (!subscriberData || !subscriberData.id) {
        return (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                <div className="bg-white rounded-lg p-6 w-full max-w-md mx-3 sm:mx-0">
                    <div className="flex justify-between items-center mb-4">
                        <h2 className="text-xl font-bold">Error</h2>
                        <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
                            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                    <p>No subscriber data available. Please try again.</p>
                    <button
                        onClick={onClose}
                        className="w-full mt-4 p-2 text-blue-500 bg-transparent border border-blue-500 rounded hover:bg-blue-50"
                    >
                        Close
                    </button>
                </div>
            </div>
        );
    }

    const validateEmail = (email) => {
        return /\S+@\S+\.\S+/.test(email);
    };

    const handleSendVerificationCode = async () => {
        // if (!validateEmail(email)) {
        //     setError('Please enter a valid email address.');
        //     return;
        // }
        
        setIsLoading(true);
        setError('');
        
        try {
            await api.post(`${api_url}/user/${userIdToUse}/subscriber/${subscriberData?.id}/verification-notification`, 
                {
                    email: email,
                    companyId: subscriberData.id
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                        
                    }
                }
            );
            toast.success('Verification code sent')
            setStep(1);
        } catch (err) {
            setError(err.response?.data?.message || 'Failed to send verification code');
        } finally {
            setIsLoading(false);
        }
    };

    const handleVerifyCode = async () => {
        if (verificationCode.length !== 6) {
            setError('Please enter a valid 6-digit verification code.');
            return;
        }

        setIsLoading(true);
        setError('');
        setStep(2);

        try {
            await api.post(`${api_url}/user/${userIdToUse}/subscriber/${subscriberData?.id}/verify`, 
                {
                    email: email,
                    verification_code: verificationCode,
                    companyId: subscriberData.id
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                                         }
                }
            );
            setTimeout(() => setStep(3), 1000);
        } catch (err) {
            setError(err.response?.data?.message || 'Verification failed');
            setStep(1);
        } finally {
            setIsLoading(false);
        }
    };

    const steps = [
        {
            title: 'Account Verification',
            content: (
                <>
                    <p className="mb-4">To proceed, please verify the account details associated with {subscriberData.company_name}.</p>
                    <label className="block mb-2">Email</label>
                    <div className='border border-slate-200 p-2 w-full rounded-md mb-4'>
                        {subscriberData.email}
                    </div>
                    {error && <p className="text-red-500 mb-2 text-xs">{error}</p>}
                    <div className='flex items-center gap-5'>
                        <button
                            onClick={handleSendVerificationCode}
                            disabled={isLoading}
                            className={`w-full p-2 text-white bg-blue-500 rounded hover:bg-blue-600 ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                        >
                            {isLoading ? 'Sending...' : 'Send Code'}
                        </button>
                        <button
                            onClick={onClose}
                            disabled={isLoading}
                            className="w-full p-2 text-blue-500 bg-transparent border border-blue-500 rounded hover:bg-blue-50"
                        >
                            Cancel
                        </button>
                    </div>
                </>
            )
        },
        {
            title: 'Account Verification',
            content: (
                <div>
                    <label className="block mb-2">Verification Code</label>
                    <input
                        type="text"
                        value={verificationCode}
                        onChange={(e) => setVerificationCode(e.target.value.replace(/[^0-9]/g, '').slice(0, 6))}
                        placeholder="Enter 6-digit Code"
                        className="w-full p-2 mb-4 border rounded"
                        maxLength={6}
                        disabled={isLoading}
                    />
                    {error && <p className="text-red-500 mb-2 text-xs">{error}</p>}
                    <div className='flex items-center gap-5'>
                        <button
                            onClick={handleVerifyCode}
                            disabled={isLoading}
                            className={`w-full p-2 text-white bg-blue-500 rounded hover:bg-blue-600 ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                        >
                            {isLoading ? 'Verifying...' : 'Verify'}
                        </button>
                        <button
                            onClick={() => setStep(0)}
                            disabled={isLoading}
                            className="w-full p-2 text-blue-500 bg-transparent border border-blue-500 rounded hover:bg-blue-50"
                        >
                            Back
                        </button>
                    </div>
                </div>
            )
        },
        {
            title: 'Account Verification',
            content: (
                <div className="text-center">
                    <p className="mb-4">Verifying...</p>
                    <div className="animate-pulse">{verificationCode}</div>
                </div>
            )
        },
        {
            title: 'Account Verification',
            content: (
                <div className="text-center">
                    <div className="mx-auto w-12 h-12 bg-green-100 rounded-full flex items-center justify-center mb-4">
                        <svg className="w-6 h-6 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                        </svg>
                    </div>
                    <p>Verification successful! You can now access the company details.</p>
                </div>
            )
        }
    ];

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[100]">
            <div className="bg-white rounded-lg p-6 w-full max-w-md mx-3 sm:mx-0 relative">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-xl font-bold">{steps[step].title}</h2>
                    <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
                <div>{steps[step].content}</div>
            </div>
        </div>
    );
};

export default SubscriberVerificationModal;