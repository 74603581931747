import React, { useState } from 'react';
import { DNA } from 'react-loader-spinner';
import { toast } from 'react-toastify';
import api from '../utils/Api';
import { api_url } from '../config';
import UpdateSubscriberProfile from './UpdateSubscriberProfile';
import AddIssueForm from './AddIssueForm';
import SubscriptionsPerAccount from './SubscriptionsPerAccount';
import SubscriberVerificationModal from './SubscriberVerificationModal';

const SubscriberDetailsModal = ({ isOpen, onClose, subscriber, onUpdateSuccess }) => {
    const [loading, setLoading] = useState(false);
    const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
    const [isFormModalOpen, setIsFormModalOpen] = useState(false);
    const [isVerificationModalOpen, setIsVerificationModalOpen] = useState(false);
    const [activeTab, setActiveTab] = useState('profile'); // 'profile', 'issues', 'subscriptions'

    if (!isOpen || !subscriber) return null;

    const handleVerify = () => {
        setIsVerificationModalOpen(true);
    };

    const handleProfileUpdate = (updatedSubscriber) => {
        // Update the local subscriber data
        if (onUpdateSuccess) {
            onUpdateSuccess(updatedSubscriber);
        }
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4 overflow-y-auto">
            <div className="bg-white rounded-xl w-full max-w-screen-xl max-h-[90vh] flex flex-col shadow-2xl">
                {/* Header */}
                <div className="flex justify-between items-center p-4 md:p-6 border-b border-gray-100 bg-gradient-to-r from-blue-50 to-white rounded-t-xl sticky top-0 z-10">
                    <div className="flex items-center gap-2 md:gap-4">
                        <div className="relative">
                            <img
                                src={`https://ui-avatars.com/api/?name=${subscriber.company_name || 'Unknown'}&background=random&bold=true&size=64`}
                                alt={`${subscriber.company_name || 'Company'} Logo`}
                                className="h-12 w-12 md:h-16 md:w-16 rounded-xl shadow-lg ring-2 ring-white"
                            />
                            {subscriber.is_verified && (
                                <div className="absolute -bottom-1 -right-1 md:-bottom-2 md:-right-2 bg-blue-500 rounded-full p-1">
                                    <svg className="w-3 h-3 md:w-4 md:h-4 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                                    </svg>
                                </div>
                            )}
                        </div>
                        <div>
                            <h2 className="text-lg md:text-2xl font-bold text-gray-800 line-clamp-1">
                                {subscriber.company_name || 'Unknown Company'}
                            </h2>
                            <p className="text-xs md:text-sm text-gray-500">ID: {subscriber.id}</p>
                        </div>
                    </div>
                    <button 
                        onClick={onClose} 
                        className="text-gray-500 hover:text-gray-700 transition-colors"
                        aria-label="Close modal"
                    >
                        <svg className="w-5 h-5 md:w-6 md:h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>

                {/* Tabs */}
                <div className="border-b border-gray-100 bg-white sticky top-16 md:top-20 z-10">
                    <div className="flex overflow-x-auto px-4 md:px-6 hide-scrollbar">
                        <button
                            onClick={() => setActiveTab('profile')}
                            className={`py-3 px-2 md:py-4 md:px-4 border-b-2 font-medium text-xs md:text-sm whitespace-nowrap transition-colors ${
                                activeTab === 'profile'
                                    ? 'border-blue-500 text-blue-600'
                                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                            }`}
                        >
                            Profile
                        </button>
                        <button
                            onClick={() => setActiveTab('issues')}
                            className={`py-3 px-2 md:py-4 md:px-4 border-b-2 font-medium text-xs md:text-sm whitespace-nowrap transition-colors ${
                                activeTab === 'issues'
                                    ? 'border-blue-500 text-blue-600'
                                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                            }`}
                        >
                            Issues
                        </button>
                        <button
                            onClick={() => setActiveTab('subscriptions')}
                            className={`py-3 px-2 md:py-4 md:px-4 border-b-2 font-medium text-xs md:text-sm whitespace-nowrap transition-colors ${
                                activeTab === 'subscriptions'
                                    ? 'border-blue-500 text-blue-600'
                                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                            }`}
                        >
                            Subscriptions
                        </button>
                    </div>
                </div>

                {/* Content */}
                <div className="flex-1 overflow-y-auto p-4 md:p-6">
                    {loading ? (
                        <div className="flex justify-center items-center h-64">
                            <DNA
                                visible={true}
                                height={80}
                                width={80}
                                ariaLabel="dna-loading"
                                wrapperStyle={{}}
                                wrapperClass="dna-wrapper"
                            />
                        </div>
                    ) : (
                        <>
                            {activeTab === 'profile' && (
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8">
                                    <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-4 md:p-6">
                                        <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center mb-4 md:mb-6 gap-2">
                                            <h3 className="text-base md:text-lg font-semibold text-gray-800">Contact Information</h3>
                                            <div className="flex gap-2">
                                                <button
                                                    onClick={handleVerify}
                                                    className="px-3 py-1.5 md:px-4 md:py-2 text-xs md:text-sm font-medium bg-green-50 text-green-600 rounded-lg hover:bg-green-100 transition-colors focus:ring-2 focus:ring-green-400 focus:outline-none flex items-center"
                                                >
                                                    <svg className="w-3 h-3 md:w-4 md:h-4 mr-1 md:mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                    </svg>
                                                    Verify
                                                </button>
                                                <button
                                                    onClick={() => setIsProfileModalOpen(true)}
                                                    className="px-3 py-1.5 md:px-4 md:py-2 text-xs md:text-sm font-medium bg-blue-50 text-blue-600 rounded-lg hover:bg-blue-100 transition-colors focus:ring-2 focus:ring-blue-400 focus:outline-none flex items-center"
                                                >
                                                    <svg className="w-3 h-3 md:w-4 md:h-4 mr-1 md:mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                                                    </svg>
                                                    Edit
                                                </button>
                                            </div>
                                        </div>
                                        <div className="space-y-3 md:space-y-4">
                                            <div className="flex items-center p-2 md:p-3 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors">
                                                <svg className="w-4 h-4 md:w-5 md:h-5 mr-2 md:mr-3 text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                                                </svg>
                                                <a href={`mailto:${subscriber.email}`} className="text-blue-600 hover:text-blue-800 text-sm md:text-base">
                                                    {subscriber.email || 'No email provided'}
                                                </a>
                                            </div>
                                            {subscriber.company_phone && (
                                                <div className="flex items-center p-2 md:p-3 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors">
                                                    <svg className="w-4 h-4 md:w-5 md:h-5 mr-2 md:mr-3 text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                                                    </svg>
                                                    <a href={`tel:${subscriber.company_phone}`} className="text-blue-600 hover:text-blue-800 text-sm md:text-base">
                                                        {subscriber.company_phone}
                                                    </a>
                                                </div>
                                            )}
                                            <div className="flex items-center p-2 md:p-3 bg-gray-50 rounded-lg">
                                                <svg className="w-4 h-4 md:w-5 md:h-5 mr-2 md:mr-3 text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                                                </svg>
                                                <span className="text-gray-600 text-sm md:text-base">{subscriber.company_address || 'Address not provided'}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-4 md:p-6">
                                        <h3 className="text-base md:text-lg font-semibold text-gray-800 mb-4 md:mb-6">Admin Information</h3>
                                        <div className="space-y-3 md:space-y-4">
                                            <div className="flex items-center p-2 md:p-3 bg-gray-50 rounded-lg">
                                                <svg className="w-4 h-4 md:w-5 md:h-5 mr-2 md:mr-3 text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                                                </svg>
                                                <span className="text-gray-600 text-sm md:text-base">
                                                    {subscriber.admin_name || subscriber.admin || 'No admin name provided'}
                                                </span>
                                            </div>
                                            <div className="flex items-center p-2 md:p-3 bg-gray-50 rounded-lg">
                                                <svg className="w-4 h-4 md:w-5 md:h-5 mr-2 md:mr-3 text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                                </svg>
                                                <span className="text-gray-600 text-sm md:text-base">
                                                    Created: {subscriber.created_at ? new Date(subscriber.created_at).toLocaleDateString('en-US', {
                                                        year: 'numeric',
                                                        month: 'long',
                                                        day: 'numeric'
                                                    }) : 'Date not available'}
                                                </span>
                                            </div>
                                            {subscriber.updated_at && (
                                                <div className="flex items-center p-2 md:p-3 bg-gray-50 rounded-lg">
                                                    <svg className="w-4 h-4 md:w-5 md:h-5 mr-2 md:mr-3 text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                                                    </svg>
                                                    <span className="text-gray-600 text-sm md:text-base">
                                                        Updated: {new Date(subscriber.updated_at).toLocaleDateString('en-US', {
                                                            year: 'numeric',
                                                            month: 'long',
                                                            day: 'numeric'
                                                        })}
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}

                            {activeTab === 'issues' && (
                                <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-4 md:p-6">
                                    <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center mb-4 md:mb-6 gap-2">
                                        <h3 className="text-base md:text-lg font-semibold text-gray-800">Issue Backlogs</h3>
                                        <button
                                            onClick={() => setIsFormModalOpen(true)}
                                            className="px-3 py-1.5 md:px-4 md:py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 text-xs md:text-sm font-medium flex items-center self-start sm:self-auto"
                                        >
                                            <svg className="w-3 h-3 md:w-4 md:h-4 mr-1 md:mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                                            </svg>
                                            Add Issue
                                        </button>
                                    </div>
                                    <div className="overflow-x-auto">
                                        <table className="min-w-full divide-y divide-gray-200">
                                            <thead className="bg-gray-50">
                                                <tr>
                                                    <th className="px-3 py-2 md:px-6 md:py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                                                    <th className="px-3 py-2 md:px-6 md:py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Issue ID</th>
                                                    <th className="px-3 py-2 md:px-6 md:py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Details</th>
                                                    <th className="px-3 py-2 md:px-6 md:py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Assignee</th>
                                                    <th className="px-3 py-2 md:px-6 md:py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                                                </tr>
                                            </thead>
                                            <tbody className="bg-white divide-y divide-gray-200">
                                                {subscriber.backlogs?.map((item) => (
                                                    <tr key={item.id} className="hover:bg-gray-50 transition-colors">
                                                        <td className="px-3 py-2 md:px-6 md:py-4 whitespace-nowrap text-xs md:text-sm text-gray-500">
                                                            {new Date(item.created_at).toLocaleDateString()}
                                                        </td>
                                                        <td className="px-3 py-2 md:px-6 md:py-4 whitespace-nowrap text-xs md:text-sm text-gray-900">{item.id}</td>
                                                        <td className="px-3 py-2 md:px-6 md:py-4 text-xs md:text-sm text-gray-900 line-clamp-1">{item.description}</td>
                                                        <td className="px-3 py-2 md:px-6 md:py-4 whitespace-nowrap text-xs md:text-sm text-gray-500 line-clamp-1">{item.assignee}</td>
                                                        <td className="px-3 py-2 md:px-6 md:py-4 whitespace-nowrap">
                                                            <span className={`px-2 py-0.5 inline-flex text-xs leading-4 font-semibold rounded-full
                                                                ${item.status === 'confirmed' ? 'bg-green-100 text-green-800' :
                                                                  item.status === 'in_progress' ? 'bg-blue-100 text-blue-800' :
                                                                  item.status === 'pending' ? 'bg-yellow-100 text-yellow-800' :
                                                                  item.status === 'resolved' ? 'bg-purple-100 text-purple-800' : ''}`}>
                                                                {item.status === 'resolved' ? 'Resolved' :
                                                                 item.status === 'in_progress' ? 'In Progress' :
                                                                 item.status === 'pending' ? 'Pending' :
                                                                 item.status === 'confirmed' ? 'Confirmed' : ''}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            )}

                            {activeTab === 'subscriptions' && (
                                <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-4 md:p-6">
                                    <SubscriptionsPerAccount subscriptions={subscriber.subscriptions} />
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>

            {/* Modals */}
            <UpdateSubscriberProfile
                isOpen={isProfileModalOpen}
                onClose={() => setIsProfileModalOpen(false)}
                subscriber={subscriber}
                onUpdateSuccess={handleProfileUpdate}
            />
            <AddIssueForm
                isOpen={isFormModalOpen}
                onClose={() => setIsFormModalOpen(false)}
                subscriber={subscriber}
            />
            <SubscriberVerificationModal
                isOpen={isVerificationModalOpen}
                onClose={() => setIsVerificationModalOpen(false)}
                subscriber={subscriber}
            />
        </div>
    );
};

export default SubscriberDetailsModal;